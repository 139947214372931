//DEPS
import { CloseOutlined } from "@ant-design/icons";
import Modal from "antd/lib/modal/Modal";
import WarningIcon from "../../assets/warning.svg";
import React from "react";

export default function ConfirmationModal(props) {
  return (
    <Modal width={500} visible={props.visible} centered footer={null} keyboard={true} onCancel={()=>props.setVisible(false)}>
      <div className="relative">
        <div className="absolute right-3">
          <CloseOutlined
            onClick={() => props.setVisible(false)}
            className="text-2xl"
          ></CloseOutlined>
        </div>
        <div className="flex flex-col  space-y-16 justify-center text-center">
          <div className=" mt-5 mx-auto">
            <img src={WarningIcon} alt="success-image"></img>
          </div>
          <div className="text-body-large">
            {props.text ? props.text : `Are you sure?`}
          </div>
          <div className="w-full text-center items-center">
            {props.showCancelButton && (
              <button
                className="bg-inactive w-3/4 px-20 py-2 rounded-full text-body-bold"
                onClick={() => props.setVisible(false)}
              >
                {props.backButtonText ? props.backButtonText : `Go back`}
              </button>
            )}
            <button
              className="bg-oro-gradient w-3/4 px-20 py-2 mt-4 rounded-full text-body-bold"
              onClick={() => props.onConfirm()}
            >
              {props.buttonText ? props.buttonText : `Confirm`}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
