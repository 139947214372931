// import { Spin } from "antd";
import React, { useState, useEffect } from 'react';
import { hot } from 'react-hot-loader';
import { withProfiler } from "@sentry/react";
// import './less/index.css'
// import { hot } from "react-hot-loader";
import Routes from './routes';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { authVerification } from './utils/userSessions';
import Api from './utils/ApiUtils/Api';
import { getValueFromLocalStorage } from './utils/local-storage';
import { checkIfComponentHasAccess } from './utils';
import { GetUserPermissions } from './contexts/permission-context';
import permissions from './utils/permissionConfig';
import { NetworkStatusProvider } from './components/NetworkStatusContext';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
export const OfferContext = React.createContext();

const App = () => {
  const { permissions: userPermissions } = GetUserPermissions();
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [pendingCount, setPendingCount] = useState(0);

  useEffect(() => {
    if (!isAuthenticated && getValueFromLocalStorage('token')) {
      authVerification()
        .then(() => {
          setAuthenticated(true);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [isAuthenticated]);

  const isOfferDetailsAllowed = checkIfComponentHasAccess(
    userPermissions,
    permissions.oro_offer.permission,
    false
  )

  const getPendingOfferCount = () => {
    if(!isOfferDetailsAllowed){
      return;
    }
    Api.get('offer/count')
      .then((res) => {
        setPendingCount(res.pendingCount);
      })
      .catch(() => {
        console.log('error');
      });
  };

  return (
		<Spin indicator={antIcon} spinning={loading || false}>
			<OfferContext.Provider
				value={{
					pendingCount,
					getPendingOfferCount,
				}}
      >
        <NetworkStatusProvider>
					<div className='App Container bg-gray-100'>
						<Routes setAuthenticated={setAuthenticated} />
          </div>
          </NetworkStatusProvider>
			</OfferContext.Provider>
		</Spin>
	);
};

export default withProfiler(hot(module)(App));
// export default App;
