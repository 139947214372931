import React from 'react';
import { NETWORK_CHECK_POLLING_TIME } from '../../utils/constants';
import { checkInternetConnection } from '../../utils/helpers';
const NetworkStatusContext = React.createContext({
	isOnline: true,
});

export function NetworkStatusProvider({
	children,
}) {
	const [isOnline, setIsOnline] = React.useState(true);
	const value = React.useMemo(() => ({ isOnline }), [isOnline]);

	const checkConnection = async () => {
		const status = await checkInternetConnection();
		setIsOnline(status);
	};

	const handleOffline = () => {
		setIsOnline(false);
	};

	React.useEffect(() => {
		window.addEventListener('offline', handleOffline);

		const checkConnectionInterval = setInterval(() => {
			checkConnection();
		}, NETWORK_CHECK_POLLING_TIME);

		return () => {
			window.removeEventListener('offline', handleOffline);
			clearInterval(checkConnectionInterval);
		};
	}, []);

	return (
		<NetworkStatusContext.Provider value={value}>
			{children}
		</NetworkStatusContext.Provider>
	);
}

export function useNetworkStatus() {
	const context = React.useContext(NetworkStatusContext);

	if (context === undefined) {
		throw new Error(
			'useNetworkStatus must be used within NetworkStatusProvider'
		);
	}

	return context;
}
