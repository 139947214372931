import React from 'react';

function PaisaIconSvg({
	width = '24',
	height = '24',
	stroke = '#909090',
}) {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M5 21V3H12.8128C14.7872 3 16.3106 3.49362 17.383 4.48085C18.4553 5.46809 18.9915 6.84681 18.9915 8.61702C18.9915 10.3872 18.4553 11.7745 17.383 12.7787C16.3106 13.766 14.7872 14.2596 12.8128 14.2596H8.26808V21H5ZM8.26808 11.6809H12.3021C14.6511 11.6809 15.8255 10.6596 15.8255 8.61702C15.8255 6.59149 14.6511 5.57872 12.3021 5.57872H8.26808V11.6809Z'
				fill={stroke}
			/>
		</svg>
	);
}

export default PaisaIconSvg;
