import React from 'react';

function ArrowLeftSidebar({
	width = '8',
	height = '14',
	className = '',
}) {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 8 14'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			className={className}
		>
			<path
				d='M2.8284 7.0007L7.7782 11.9504L6.364 13.3646L0 7.0007L6.364 0.636719L7.7782 2.05093L2.8284 7.0007Z'
				fill='#919191'
			/>
		</svg>
	);
}

export default ArrowLeftSidebar;
