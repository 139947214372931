import gql from 'graphql-tag';
export const PING_RESOURCE_URL = '/robots.txt';
export const REQUEST_TIMEOUT = 60000;
export const HYPERTRACK_BASE_URL = 'https://embed.hypertrack.com';
export const NETWORK_CHECK_POLLING_TIME = 10000;
export const HYPERTRACK_PUBLISHABLE_KEY =
	process.env.REACT_APP_HYPERTRACK_PUBLISHABLE_KEY;
export const INITIAL_AGENT_TRACKING_INFO = {
	totalDistanceTravelled: 0,
	totalTripsTaken: 0,
};

export const FEDERAL_NOT_DONE_STATES = ["Pending", "Requested", "CST Approval Pending"];
export const FEDERAL_USER_STATES = {
  CHECKING: "checking",
  IN_PROGRESS: "in-progress",
  VERIFIED: "verified",
  NOT_VERIFIED: "not-verified",
};

export const LOAN_FEDERAL_CHECK_TITLES = {
  GL_OPENING_COMPLETED: "GL Account Opening",
  GL_VALIDATION_COMPLETED: "GL Validation",
  GL_INSERTION_COMPLETED: "GL Account Insert",
  PLEDGE_CARD_UPDATED: "Pledge Card Submitted",
};

export const INITIAL_LOAN_FEDERAL_CHECK_STATE = (isError) => [
  {
    title: LOAN_FEDERAL_CHECK_TITLES.GL_OPENING_COMPLETED,
    value: isError ? false : undefined,
  },
  {
    title: LOAN_FEDERAL_CHECK_TITLES.GL_VALIDATION_COMPLETED,
    value: isError ? false : undefined,
  },
  {
    title: LOAN_FEDERAL_CHECK_TITLES.GL_INSERTION_COMPLETED,
    value: isError ? false : undefined,
  },
];

export const LOAN_GL_STATUS_TITLES = {
  LOAN_REQUEST: "Loan Request",
  PLEDGE_CARD: "Pledge Card",
  FUND_TRANSFER: "Fund Transfer",
  AGENT_VERIFICATION: "Agent Verification",
  LOAN_BRANCH: "Loan Branch",
  ACCOUNT: "Account Created",
  SECURITY: "Security",
  LOAN_DISBURSEMENT: "Loan Disbursement",
};

export const INITIAL_LOAN_GL_STATE = (isError) => [
  {
    title: LOAN_GL_STATUS_TITLES.LOAN_REQUEST,
    value: isError ? false : undefined,
  },
  {
    title: LOAN_GL_STATUS_TITLES.PLEDGE_CARD,
    value: isError ? false : undefined,
  },
  {
    title: LOAN_GL_STATUS_TITLES.FUND_TRANSFER,
    value: isError ? false : undefined,
  },
];

export const USER_FEDERAL_CHECK_TITLES = {
  EKYC_VERIFIED: "eKYC Verified",
  NAME_DOB_VALIDATED: "Name & DOB Validation",
  PAN_VALIDATED: "PAN Validated",
  UN_NAME_CHECKED: "UN Name Check",
  DEDUPE_CHECK: "Dedupe Checked",
  CUSTOMER_CREATED: "Customer Created",
  DEDUPE_CHECK_STATUS: "Customer Type",
  IS_MIN_KYC_CUSTOMER: "KYC Completed"
};

export const INITIAL_USER_FEDERAL_CHECK_STATE = (isError) => [
  {
    title: USER_FEDERAL_CHECK_TITLES.NAME_DOB_VALIDATED,
    value: isError ? false : undefined,
  },
  {
    title: USER_FEDERAL_CHECK_TITLES.DEDUPE_CHECK_STATUS,
    value: isError ? false : undefined,
  },
  {
    title: USER_FEDERAL_CHECK_TITLES.PAN_VALIDATED,
    value: isError ? false : undefined,
  },
  {
    title: USER_FEDERAL_CHECK_TITLES.UN_NAME_CHECKED,
    value: isError ? false : undefined,
  },
];

export const GET_USER_BANK_ACCOUNT_DETAILS = gql`
	fragment userBankAccountDetailsNew on user_bank_account_details {
		id
		bank_name
		bank_branch
		ifsc
		bank_account_name
		bank_account_number
		bank_proof
		updated_at
		is_verified
		verification_attempts
	}

	query getUserBankAccountDetailsNew($auth_id: String!) {
		user_bank_account_details(
			where: { user_auth_id: { _eq: $auth_id }, is_active: { _eq: true } }
			order_by: { updated_at: desc }
		) {
			...userBankAccountDetailsNew
			user {
				id
				mobile_number
				kycs {
					id
					legal_name
				}
				customer {
					customers_customer_details {
						bank_account_type
					}
				}
			}
		}
	}
`;

export const UPDATE_VERIFICATION_STATUS = gql`
	mutation updateVerificationStatus(
		$id: Int!
		$bankAccountId: Int!
		$activity_logs: jsonb
	) {
		update_user_bank_account_details(
			where: { user: { id: { _eq: $id } }, id: { _eq: $bankAccountId } }
			_set: { is_verified: true }
		) {
			affected_rows
		}
		update_customers(
			where: { user: { id: { _eq: $id } } }
			_append: { activity_logs: $activity_logs }
		) {
			affected_rows
		}
	}
`;

export const FEDERAL_CHECKS_NOT_RENDERED_BUT_VALIDATED = []

export const ALL_INDIAN_STATES = [
	'Andhra Pradesh',
	'Arunachal Pradesh',
	'Assam',
	'Bihar',
	'Chhattisgarh',
	'Goa',
	'Gujarat',
	'Haryana',
	'Himachal Pradesh',
	'Jammu and Kashmir',
	'Jharkhand',
	'Karnataka',
	'Kerala',
	'Madhya Pradesh',
	'Maharashtra',
	'Manipur',
	'Meghalaya',
	'Mizoram',
	'Nagaland',
	'Odisha',
	'Punjab',
	'Rajasthan',
	'Sikkim',
	'Tamil Nadu',
	'Telangana',
	'Tripura',
	'Uttarakhand',
	'Uttar Pradesh',
	'West Bengal',
	'Andaman and Nicobar Islands',
	'Chandigarh',
	'Dadra and Nagar Haveli',
	'Daman and Diu',
	'Delhi',
	'Lakshadweep',
	'Puducherry',
]

export const OPERATIONAL_CITIES = {
	CHENNAI: 'Chennai',
	HYDERABAD: 'Hyderabad',
	BENGALURU: 'Bengaluru',
	PUNE: 'Pune',
	VIJAYAWADA: 'Vijayawada',
	KOCHI: 'Kochi'
}

export const GL_PARTNER_CODE = {
  FEDERAL: 'Federal',
  FINCARE: 'Fincare',
  TPF:'TPF',
  SFL:'SFL'
}

export const FEDERAL_CASES = {
  CASE_1: 'New Customer & PAN Available',
  CASE_2: 'New Customer & Form 60 Available',
  CASE_3: 'Old Customer & min_kyc is false',
  CASE_4: 'Old Customer & min_kyc is true'
}

export const FEE_TYPE = {
  percentage: 'PERCENTAGE',
  flat: 'FLAT',
}

export const LOAN_TYPES = {
  GOLD_LOAN:'GL',
  SUPPORT_LOAN : 'SL',
  BRIDGE_LOAN : 'BRL'

}
export const KYC_FILTER_STATES_COBORROWER = {
	REVIEW_PENDING: 'REVIEW_PENDING',
	NOT_DONE: 'NOT_DONE',
	COMPLETED: 'COMPLETED',
	ALL_USERS: 'ALL',
};
export const  KYC_FILTER_STATES_USERS = {
	REVIEW_PENDING: 'review-pending',
	NOT_DONE: 'not-done',
	COMPLETED: 'completed',
	ALL_USERS: 'all-users',
  NPA:'npa'
};


export const VISIT_STATUS = {
  VISIT_ASSIGNED : 'VISIT_ASSIGNED',
  VISIT_REQUESTED:"VISIT_REQUESTED",
  VISIT_CANCELLED:'VISIT_CANCELLED',
  VISIT_CONFIRMED:'VISIT_CONFIRMED',
  VISIT_COMPLETED:'VISIT_COMPLETED'

}

export const VISIT_TYPE = {
  GP:'GP',
  GR:'GR'
}

export const MAIL_SENT_STATUS = {
  SUCCESS:"SUCCESS",
  FAILURE:"FAILURE"
}

export const LOG_EVENT = {
  EMAIL : 'email'
}

export const FEEDBACK_OPTION_ORDER = [
	'Pre-Loan Service',
	'Partner Support',
	'Speed of the loan process',
	'Loan Schemes',
	'Instant Fund Transfer',
];

export const LOAN_PROVIDERS = {
  FEDERAL: 'FEDERAL'
}