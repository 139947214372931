
const permissions = {
    visits: {
        create_new_visit: {
            permission: ["CREATE_NEW_MANUAL_VISIT"]
        },
        set_holiday_visit_timing: {
            permission: ["EDIT_VISIT_STATUS_AND_TIMINGS"],
        },
        view_masked_number: {
            permission: ['VIEW_MASKED_NUMBER_GL']
        },
        view_unmasked_number: {
            permission: ['VIEW_UNMASKED_NUMBER_GL'],
        },
        table_row: {
            permission: ["VIEW_VISITS"],
            edit_visit_details: {
                permission: ["VIEW_VISIT_DETAIL_GL"],
                change_partner_branch: {
                    permission: ["EDIT_VISIT_BRANCH"]
                },
                edit_visit_data: {
                    permission: ["EDIT_VISIT_DATA"]
                },
                edit_visit_status: {
                    permission: ["EDIT_VISIT_STATUS"]
                },
                edit_visit_channel_and_utm: {
                    permission: ["EDIT_VISIT_CHANNEL_UTM_GL"]
                },
                edit_visit_reassign_agent: {
                    permission: ["EDIT_VISIT_REASSIGN_AGENT_GL"]
                }
            },
            loan_id_details: { 
                permission: ["VIEW_LOANS"],
            },
            agent_id_details: {
                permission: ["VIEW_AGENT_DETAIL"],
            },
            view_customer_details: {
                permission: ["VIEW_USER"],
            },
            offer_id_details: {
                permission: ["VIEW_OFFER"]
            },
            confirm_requested_visit: {
                permission: ["CONFIRM_REQUESTED_VISIT_GL"]
            },
            assign_confirmed_visit: {
                permission: ["EDIT_VISIT_REASSIGN_AGENT_GL"]
            },
            view_visit_location_tracking: {
                permission: ["VIEW_VISIT_LOCATION_TRACKING_GL"]
            }
        },
        permission: ["VIEW_VISITS"],
    },
    loans: {
        permission: ["VIEW_LOANS"],
        export: {
            permission: ["EXPORT_LOAN"]
        },
        table_row: {
            approve_loan: {
                permission: ["EDIT_LOAN"],
            },
            permission: ["VIEW_LOANS"],
            visit_id_details: {
                permission: []
            },
            edit_loan_details: {
                permission: ["EDIT_LOAN"],
            },
            edit_gold_entry: {
                permission: ["EDIT_GOLD_ENTRY"],
            },
            record_renewal_fund_transfer: {
                permission: ["EDIT_LOAN"],
            },
            record_fund_transfer: {
                permission: ["EDIT_LOAN"],
            },
            customer_info: {
                permission: ["VIEW_USER"],
            },
            view_digital_pledge_cards_gl_visit: {
                permission: ["VIEW_PLEDGE_CARD"] 
            },
            view_signed_pledge_cards_gl_visit: {
                permission: ["VIEW_PLEDGE_CARD"]
            },
            view_digital_pledge_cards_sl_visit: {
                permission: ["VIEW_PLEDGE_CARD"] 
            },
            view_signed_pledge_cards_sl_visit: {
                permission: ["VIEW_PLEDGE_CARD"]
            },
            view_gl_visit_kfs: {
                permission: ["VIEW_PLEDGE_CARD"] 
            },
            view_sl_visit_kfs: {
                permission: ["VIEW_PLEDGE_CARD"]
            },
            view_signed_pledge_cards_release_visit: {
                permission: ["VIEW_PLEDGE_CARD"] 
            },
            agent_id_details: {
                permission: ["VIEW_AGENT_DETAIL"] 
            }
        },
    },
    takeover: {
        permission: ["VIEW_LOANS"],
        takeover: {
            permission: ["VIEW_LOANS"]
        }
    },
    support_loans: {
        permission: ["VIEW_SUPPORT_LOAN"],
        loan_id_details: {
            permission: ["VIEW_SUPPORT_LOAN"],
        }
    },
    closure_friction: {
		permission: ['VIEW_CLOSURE'],
		toggle: {
			permisssion: ['EDIT_CLOSURE'],
		},
	},
    agents: {
        permission: ["VIEW_AGENT_DETAIL"],
        create_new_agent: {
            permission: ["CREATE_AGENT"],
        },
        table_row: {
            permission: ["VIEW_AGENT_DETAIL"],
            edit_agent: {
                permission: ["EDIT_AGENT"]
            },
            make_inactive: {
                permission: ["TOGGLE_AGENT_ACTIVE"]
            },
            id_proof_picture:{ 
                permission:[]
            }
        },
    },
    users: {
        permission: ["VIEW_USER"],
        register_new_user: {
            permission: ["REGISTER_NEW_USER"],
        },
        export: {
            permission: ["EXPORT_USER"],
        },
        sfl:{
            permission:['SFL']
        },
        view_masked_mobile_number :{
            permission : ['VIEW_MASKED_NUMBER_UD']
        },
        view_unmasked_mobile_number: {
            permission: ['VIEW_UNMASKED_NUMBER_UD']
        },
        export_credit_report_all_users: {
            permission: ["EXPORT_CREDIT_REPORT_ALL_USERS"]
        },
        export_credit_report_all_users: {
            permission: ["EXPORT_CREDIT_REPORT_ALL_USERS"]
        },
        table_row: {
            loan_id_details: {
                permission: ["VIEW_LOANS"]
            },
            visit_id_details: {
                permission: ["VIEW_VISITS"]
            },
            edit_details: {
                permission: ["EDIT_USER"]
            },
            start_pre_kyc: {
                permission: ["EDIT_USER"]
            },
            add_new_bank_account: {
                permission: ["CREATE_BANK_ACCOUNT"]
            },
            verify_now: {
                permission: ["VERIFY_BANK_ACCOUNT"]
            },
            edit_bank_account_details: {
                permission: ["EDIT_BANK_ACCOUNT"]
            }

        },
    },
    coborrowers: {
        permission: ["VIEW_COBORROWER"],
        export: {
            permission: ["EXPORT_COBORROWER"],
        },
        view_masked_mobile_number: ['VIEW_MASKED_COBORROWER_MOBILE_NUMBER'],
        view_unmasked_mobile_number: ['VIEW_UNMASKED_COBORROWER_MOBILE_NUMBER'],
        table_row: {
            edit_details: {
                permission: ["EDIT_COBORROWER"]
            },
            add_new_bank_account: {
                permission: ["CREATE_BANK_ACCOUNT"]
            },
            verify_now: {
                permission: ["VERIFY_COBORROWER_BANK_ACCOUNT"]
            },
            edit_bank_account_details: {
                permission: ["CREATE_COBORROWER_BANK_ACCOUNT"]
            }

        },
    },
    reports:{
        permission: ["VIEW_KUMBHAT_REPORT"],
    },
    insights: {
        permission: ["VIEW_LOCATION_TRACKING_INSIGHT"]
    },
    oro_offer: {
        view_oro_offer:{
            permission:["VIEW_OFFER_LIST"]
        },
        permission: ["VIEW_OFFER_LIST"],
        generate_new_offer: {
            permission: ["GENERATE_NEW_OFFER"]
        },
        table_row: {
            finish_creation: {
                permission: ["VIEW_OFFER"],
            },
            view_offer: {
                permission: ["VIEW_OFFER_DETAIL"],
                agent_id_details: {
                    permission: ["VIEW_AGENT_DETAIL"] 
                },
                add_customer: {
                    permission: ["REGISTER_NEW_USER"]
                },
                offer_raised_by_details: {
                    permission: ["VIEW_OFFER"]
                },
                offer_edited_by_details: {
                    permission: ["VIEW_OFFER"]
                },
                approve_offer: {
                    permission: ["APPROVE_OFFER"]
                },
                reject_offer: {
                    permission: ["REJECT_OFFER"]
                },
                edit_customer_details: {
                    permission: ["EDIT_USER"]
                },
                edit_pledge_card: {
                    permission: ["EDIT_PLEDGE_CARD"]
                },
                delete_pledge_card: {
                    permission: ["DELETE_PLEDGE_CARD"]
                },
                add_pledge_card: {
                    permission: ["GENERATE_NEW_OFFER"]
                },
                edit_sl: {
                    permission: ["EDIT_SL"]
                },
                download_oro_offer: {
                    permission: ["DOWNLOAD_OFFER"] 
                }
            }
        }
    },
    dc: {
		dc_details: {
			permission: ['VIEW_DC'],
		},
	},
    config: {
        permission: ["VIEW_OFFER_LIST","VIEW_GOLD_MARKING","VIEW_LENDER","VIEW_PINCODE","VIEW_PLANS",  'VIEW_BANNER', "VIEW_RATE", "VIEW_PARTNER","VIEW_GOLD_ORNAMENT_TYPES",],
        plans: {
            permission: ["VIEW_PLANS"],
            export: {
                permission: ["VIEW_PLANS"],
            },
            create_new_plan: {
                permission: ["CREATE_PLAN"],
            },
            create_new_brl_plan: {
                permission: ["CREATE_BRL_PLAN"],
            },
            update_renewal_plan : {
                permission: ['SET_RENEWAL_DATE'],
            },
            upload_csv_file: {
                permission: ["UPLOAD_PLAN_CSV"]
            },
            table_row: {
                permission: ["VIEW_PLANS"],
                make_plan_inactive: {
                    permission: ["CREATE_PLAN"], // permission acc to QA-164
                },
                mark_plan_as_recommended:{
                    permission: ["EDIT_PLAN"]
                },
                add_new_interest_slab: {
                    permission: ["EDIT_PLAN"]
                },
                link_sl_plans: {
                    permission: ["EDIT_PLAN"]
                },
                recommended: {
                    permission: ["EDIT_PLAN"]
                }
            }
        },
        rates: {
            permission: ["VIEW_RATE"],
            export: {
                permission: ["VIEW_RATE"],
            },
            add_new_rate: {
                permission: ["ADD_NEW_RATE"]
            },
            view_rate_card: {
                permission: ["VIEW_RATE_DETAIL"],
            },
            table_row: {
                permission: ["VIEW_RATE_DETAIL"],
                make_rate_inactive: {
                    permission: ["EDIT_RATE"],
                }
            }
        },
        partners: {
            permission: ["VIEW_PARTNER"],
            create_new_sl_partner: {
                permission: ["CREATE_NEW_PARTNER"]
            },
            export: {
                permission: ["EXPORT_PARTNER"],
            },
            table_row: {
                permission: ["VIEW_PARTNER"],
                make_partner_inactive: {
                    permission: ["EDIT_PARTNER"],
                },
                edit_partner: {
                    permission: ["EDIT_PARTNER"],
                },
                create_new_branch: {
                    permission: ["CREATE_NEW_PARTNER"],
                },
                table_row: {
                    permission: ["VIEW_BRANCH"],
                    add_blc_or_blv:{
                        permission:["ADD_BLC_OR_BLV"]
                    },
                    edit_blc: {
                        permission: ["UPDATE_BRANCH"]
                    },
                    edit_blv: {
                        permission: ["UPDATE_BRANCH"]
                    },
                    make_branch_inactive: {
                        permission: ["EDIT_BRANCH"]
                    },
                    edit_branch_details: {
                        permission: ["EDIT_BRANCH"]
                    }
                }
            }
        },
        gold_ornaments: {
            permission: ["VIEW_GOLD_ORNAMENT_TYPES"],
            add_new_ornament: {
                permission: ["CREATE_GOLD_ENTRY"],
            },
            table_row: {
                toggle_active: {
                    permission: ["TOGGLE_GOLD_ORNAMENT"],
                },
                edit_gold_ornament: {
                    permission: ["EDIT_GOLD_ENTRY"]
                },
            }

        },
        pin_codes: {
            permission: ["VIEW_PINCODE"],
            add_pincode: {
                permission: ["ADD_PINCODE"]
            },
            table_row: {
                toggle_active: {
                    permission: ["TOGGLE_PINCODE"],
                },
            }

        },
        lenders: {
            permission: ["VIEW_LENDER"],
            add_new_lender: {
                permission: ["EDIT_LENDER"]
            },
            table_row: {
                toggle_active: {
                    permission: ["TOGGLE_LENDER"],
                },
                edit_lender: {
                    permission: ["EDIT_LENDER"],
                }
            }
        },
        gold_marking: {
            permission: ["VIEW_GOLD_MARKING"],
            add_new_type: {
                permission: ["CREATE_GOLD_MARKING"]
            },
            toggle_active: {
                permission: ['TOGGLE_GOLD_MARKING'],
            },
        },
        old_offers: {
            view_offer_list:{
                permission : ["VIEW_OLD_OFFER"]
            },
            permission: ["VIEW_OFFER"],
            generate_new_offer: {
                permission: ["GENERATE_OLD_OFFER"]
            },
            csv_export_data: {
                permission: ["EXPORT_OLD_OFFER"]
            },
            table_row: {
                edit: {
                    permission: ["EDIT_OFFER"]
                },
                view: {
                    permission: ["VIEW_OFFER"]
                },
                pledge_card_id_details: {
                    permission: ["VIEW_PLEDGE_CARD"]
                }
            }
        },
        banners: {
			permission: ['VIEW_BANNER'],
			create_new_banner: {
				permission: ['CREATE_BANNER'],
			},
			table_row: {
				edit: {
					permission: ['EDIT_BANNER'],
				},
			},
		},
    },
    other: {
        permission: [],
        view_unmasked_number:{
            permission: ["VIEW_UNMASKED_NUMBER"]
        }
    },
    paisa: {
		view_paisa: {
			permission: ['VIEW_PAISA_TAB'],
        },
        payments: {
			permission: ['VIEW_LEDGER_PAYMENT'],
			export: {
				permission: ['EXPORT_LEDGER_PAYMENT'],
			},
			reinitiate_failed_payout: {
				permission: ['REINITIATE_FAILED_PAYOUT'],
			},
		},
		refunds: {
			permission: ['VIEW_LEDGER_REFUND'],
		},
		fetchClosure: {
			permission: ['VIEW_LEDGER_LOG'],
			fetch_closure_amount: ['FETCH_CLOSURE_AMOUNT'],
		},
		fund_transfer: {
			permission: ['VIEW_LEDGER_FUND_TRANSFER'],
			export: {
				permission: ['EXPORT_LEDGER_FUND_TRANSFER'],
			},
        },
        payout: {
			permission: ['VIEW_LEDGER_PAYOUT'],
			table_row: {
				reinitiate: { permission: ['REINITIATE_FAILED_PAYOUT'] },
				refresh: { permission: ['VIEW_PAYMENT'] },
				cancel: { permission: ['DELETE_PAYMENT'] },
			},
			activate_payout: {
				permission: ['TOGGLE_LEDGER_PAYOUT'],
			},
		},
        view_paisa_sidebar_tab: {
			permission: [
				'VIEW_LEDGER_PAYMENT',
				'VIEW_LEDGER_PAYOUT',
				'VIEW_LEDGER_FUND_TRANSFER',
				'VIEW_LEDGER_REFUND',
				'FETCH_CLOSURE_AMOUNT',
			],
		},
	},
};

export default permissions;
