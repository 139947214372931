import React from 'react';

export default function UsersIconSidebar({
	width = '18',
	height = '18',
}) {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 18 18'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M8.99739 17.3307C4.39502 17.3307 0.664062 13.5997 0.664062 8.99739C0.664062 4.39502 4.39502 0.664062 8.99739 0.664062C13.5997 0.664062 17.3307 4.39502 17.3307 8.99739C17.3307 13.5997 13.5997 17.3307 8.99739 17.3307ZM8.99739 15.6641C12.6793 15.6641 15.6641 12.6793 15.6641 8.99739C15.6641 5.3155 12.6793 2.33073 8.99739 2.33073C5.3155 2.33073 2.33073 5.3155 2.33073 8.99739C2.33073 12.6793 5.3155 15.6641 8.99739 15.6641ZM4.83073 8.99739H6.4974C6.4974 10.3781 7.61664 11.4974 8.99739 11.4974C10.3781 11.4974 11.4974 10.3781 11.4974 8.99739H13.1641C13.1641 11.2986 11.2986 13.1641 8.99739 13.1641C6.69621 13.1641 4.83073 11.2986 4.83073 8.99739Z'
				fill='black'
			/>
		</svg>
	);
}
