import React from 'react';

function DcWalkInSvg({
	width = '24',
	height = '24',
	stroke = '#909090',
}) {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M20.2816 4.28042H2.13421V2H20.2816V4.28042ZM13.8166 11.1217C12.9092 12.2163 12.3421 13.6074 12.3421 15.1124C12.3421 16.4123 12.8298 17.8033 13.4763 19.1032V20.2434H2.13421V13.4021H1V11.1217L2.13421 5.42063H20.2816L21.0755 9.41137C20.3269 9.04651 19.5103 8.84127 18.6483 8.84127L18.4215 7.70106H3.99431L3.31379 11.1217H13.8166ZM11.2079 13.4021H4.40263V17.963H11.2079V13.4021ZM22.55 15.1124C22.55 18.077 18.5803 22.5238 18.5803 22.5238C18.5803 22.5238 14.6105 18.077 14.6105 15.1124C14.6105 12.946 16.4253 11.1217 18.5803 11.1217C20.7353 11.1217 22.55 12.946 22.55 15.1124ZM19.9413 15.2265C19.9413 14.5423 19.2608 13.8582 18.5803 13.8582C17.8997 13.8582 17.2192 14.4283 17.2192 15.2265C17.2192 15.9106 17.7863 16.5947 18.5803 16.5947C19.3742 16.5947 20.0547 15.9106 19.9413 15.2265Z'
				fill={stroke}
			/>
		</svg>
	);
}

export default DcWalkInSvg;
