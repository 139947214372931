import { formatLog } from ".";
import moment from "moment";
import { PING_RESOURCE_URL, REQUEST_TIMEOUT } from './constants';
export const getDefaultFileName = (item) => {
	const arr = item?.image_url.split('.') || [];
	const fileType = arr && arr.length ? arr[arr.length - 1] : 'jpg';
	return item?.fileName || (item?.type || 'default') + '.' + fileType;
};

export const sortItemsByDate = (formattedLogs, ascending, displayOrder) => {
	const logs = [...formattedLogs];
	// eslint-disable-next-line
	logs?.sort((a, b) => {
		const date1 = moment(a.datetime);
		const date2 = moment(b.datetime);
		return ascending ? date1.diff(date2) : date2.diff(date1);
	});
	if (displayOrder.displayLess) {
		return logs.slice(0, 5);
	}
	return logs;
};

	export const checker = (arr, target) => target.every((v) => arr.includes(v));

	export const preClosureMonths = [
		'Month 1',
		'Month 2',
		'Month 3',
		'Month 4',
		'Month 5',
		'Month 6',
		'Month 7',
		'Month 8',
		'Month 9',
	];
	
	export const areArraysEqualSets = (a1, a2) => {
		const superSet = {};
		for (const i of a1) {
		  const e = i + typeof i;
		  superSet[e] = 1;
		}
	  
		for (const i of a2) {
		  const e = i + typeof i;
		  if (!superSet[e]) {
			return false;
		  }
		  superSet[e] = 2;
		}
	  
		for (let e in superSet) {
		  if (superSet[e] === 1) {
			return false;
		  }
		}
	  
		return true;
	  };

	export  function convertToUtcStartAndEndDates(fromDate, toDate) {
		const finalStartDate = moment(fromDate)
			.startOf('day')
			.utcOffset(0).format();
	
		const finalEndDate = moment(toDate)
			.endOf('day')
			.utcOffset(0).format();
	
		return { finalStartDate, finalEndDate };
	}

	export const timeoutUnresolvedRequest = (
		time,
		promise
	) => {
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				reject(new Error('Request timed out.'));
			}, time);
	
			promise.then(resolve, reject);
		});
	};
	
	export const checkInternetConnection = async () => {
		const controller = new AbortController();
		const { signal } = controller;
	
		if (!navigator?.onLine) {
			return false;
		}
	
		try {
			await timeoutUnresolvedRequest(
				REQUEST_TIMEOUT,
				fetch(PING_RESOURCE_URL, {
					method: 'GET',
					signal,
				})
			);
	
			return true;
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error(error);
			controller.abort();
		}
	
		return false;
	};