import SIDEBAR_CONSTANTS from "./constants";

// * checks if the active sidebar link is config
export function isConfigSelected(activeLinks) {
	return (
		activeLinks.includes(SIDEBAR_CONSTANTS.CONFIG) ||
		activeLinks.includes(SIDEBAR_CONSTANTS.AGENTS) ||
		activeLinks.includes(SIDEBAR_CONSTANTS.INSIGHTS) ||
		activeLinks.includes(SIDEBAR_CONSTANTS.DISBURSEMENT_CENTER_DETAILS)
	);
}

// * checks if the active sidebar link is oro offer
export function isOroOfferSelected(activeLinks) {
	return activeLinks.includes(SIDEBAR_CONSTANTS.ORO_OFFERS);
}

// * checks if the active sidebar link is agents
export function isUsersSelected(activeLinks) {
	return (
		activeLinks.includes(SIDEBAR_CONSTANTS.USERS) ||
		activeLinks.includes(SIDEBAR_CONSTANTS.COBORROWER)
	);
}

// * checks if the active sidebar link is plans
export function isPlanSelected(activeLinks) {
	return activeLinks.includes(SIDEBAR_CONSTANTS.PLANS);
}

// * checks if the active sidebar link is rates
export function isRateSelected(activeLinks) {
	return activeLinks.includes(SIDEBAR_CONSTANTS.RATES);
}

// * checks if the active sidebar link is partners
export function isPartnerSelected(activeLinks) {
	return activeLinks.includes(SIDEBAR_CONSTANTS.PARTNERS);
}

// * checks if the active sidebar link is gold ornaments
export function isGoldOrnamentsSelected(activeLinks) {
	return activeLinks.includes(SIDEBAR_CONSTANTS.GOLD_ORNAMENTS);
}

// * checks if the active sidebar link is pincodes
export function isPincodesSelected(activeLinks) {
	return activeLinks.includes(SIDEBAR_CONSTANTS.PINCODES);
}

// * checks if the active sidebar link is lenders
export function isLenderssSelected(activeLinks) {
	return activeLinks.includes(SIDEBAR_CONSTANTS.LENDERS);
}

// * checks if the active sidebar link is gold markings
export function isGoldMarkingsSelected(activeLinks) {
	return activeLinks.includes(SIDEBAR_CONSTANTS.GOLD_MARKINGS);
}
// * checks if the active sidebar link is old offers
export function isOldOffersSelected(activeLinks) {
	return activeLinks.includes(SIDEBAR_CONSTANTS.OLD_OFFERS);
}
// * checks if the active sidebar link is banners
export function isBannerSelected(activeLinks) {
	return activeLinks.includes(SIDEBAR_CONSTANTS.BANNERS);
}

// * checks if the active sidebar link is insights
export function isInsightsSelected(activeLinks) {
	return activeLinks.includes(SIDEBAR_CONSTANTS.INSIGHTS);
}

// * checks if the active sidebar link is insights
export function isReportsSelected(activeLinks) {
	return activeLinks.includes(SIDEBAR_CONSTANTS.REPORTS);
}

// * checks if the active sidebar link is agents
export function isAgentSelected(activeLinks) {
	return activeLinks.includes(SIDEBAR_CONSTANTS.AGENTS);
}

// * checks if the active sidebar link is agents
export function isCoborrowerSelected(activeLinks) {
	return activeLinks.includes(SIDEBAR_CONSTANTS.COBORROWER);
}
