import React from 'react';

function ClosureRequestIconSvg({
	width = '24',
	height = '24',
	stroke = '#909090',
}) {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M1.64437 3.6224C2.05694 3.22388 2.61652 3 3.2 3H20.8C21.3835 3 21.9431 3.22388 22.3556 3.6224C22.7682 4.02091 23 4.56142 23 5.125V6.1875C23 6.75108 22.7682 7.29159 22.3556 7.6901C21.9431 8.08862 21.3835 8.3125 20.8 8.3125V17.875C20.8 18.4386 20.5682 18.9791 20.1556 19.3776C19.7431 19.7761 19.1835 20 18.6 20H5.4C4.81653 20 4.25695 19.7761 3.84437 19.3776C3.43179 18.9791 3.2 18.4386 3.2 17.875V8.3125C2.61652 8.3125 2.05694 8.08862 1.64437 7.6901C1.23179 7.29159 1 6.75109 1 6.1875V5.125C1 4.56141 1.23179 4.02091 1.64437 3.6224ZM5.4 8.3125V17.875H18.6V8.3125H5.4ZM20.8 6.1875V5.125L3.2 5.125V6.1875H20.8ZM12 9.375C12.6075 9.375 13.1 9.8507 13.1 10.4375V13.1849L13.4222 12.8737C13.8518 12.4588 14.5482 12.4588 14.9778 12.8737C15.4074 13.2886 15.4074 13.9614 14.9778 14.3763L12.7778 16.5013C12.3482 16.9162 11.6518 16.9162 11.2222 16.5013L9.02218 14.3763C8.59261 13.9614 8.59261 13.2886 9.02218 12.8737C9.45176 12.4588 10.1482 12.4588 10.5778 12.8737L10.9 13.1849V10.4375C10.9 9.8507 11.3925 9.375 12 9.375Z'
				fill={stroke}
			/>
		</svg>
	);
}

export default ClosureRequestIconSvg;
